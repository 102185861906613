import utilStyles from '../styles/utils.module.css';

const Terms = () => {
    return (
        <div className='column-layout terms'>
            <h2>Algemene voorwaarden</h2>
            <p>
                Deze Algemene Voorwaarden zijn automatisch van toepassing van zodra u de mobiele app van <span className={utilStyles.princess}>Het Dautenmannetje</span> downloadt of in gebruik neemt. Deze voorwaarden zijn ook van toepassing indien u gebruik maakt van de web-app "www.hetdautenmannetje.be".
                Lees daarom aandachtig deze Algemene Voorwaarden alvorens gebruik te maken van de app of web-app (hierna: Apps).</p>

            <p>Het is verboden om de Apps geheel of gedeeltelijk te kopiëren of aan te passen. Het is niet toegestaan om te trachten de broncode van de Apps te achterhalen, noch is het toegestaan om de Apps te vertalen naar een andere taal of een afgeleide versie ervan te produceren.
                Zowel de Apps en alle handelsmerken, copyright, databaserechten en ander intellectuele eigendomsrechten die eraan verbonden zijn, blijven het eigendom van Willem Claesen (hierna: De Bedenker) en Steve Bottelbergs (hierna: De Ontwikkelaar).
            </p>

            <p>De Ontwikkelaar tracht ervoor te zorgen dat de Apps zo nuttig en gebruiksvriendleijk zijn als mogelijk. De Ontwikkelaar behoudt daarom het recht om op ieder moment aanpassingen te doen aan de Apps, zonder de gebruiker daarvan op voorhand op de hoogte te stellen.</p>

            <p>De mobiele app van <span className={utilStyles.princess}>Het Dautenmannetje</span> bewaart en verwerkt bepaalde gegevens die u aan ons verstrekt hebt om onze dienst te kunnen verlenen. Het is uw verantwoordelijkheid om uw telefoon en toegang tot de Apps te beveiligen. Het wordt daarom aangeraden om uw telefoon niet te jailbreaken of te rooten. Dit is het proces van het verwijderen van softwarebeperkingen en beperkingen die zijn opgelegd door het officiële besturingssysteem van uw apparaat. Het kan uw telefoon kwetsbaar maken voor malware / virussen / schadelijke programma's, de beveiligingsfuncties van uw telefoon in gevaar brengen en het kan betekenen dat de mobiele app van <span className={utilStyles.princess}>Het Dautenmannetje</span> niet goed of helemaal niet werkt.</p>

            <h3>Diensten van derden</h3>
            <p>De Apps maken gebruik van diensten van derden, die elk eigen algemene voorwaarden opleggen.</p>
            <br/><br/>
            <strong>Links naar de algemene voorwaarden van de gebruikte diensten van derden:</strong>
            <ul>
                <li>
                    <a href='https://www.google.com/policies/privacy/' target='_blank' rel="noopener noreferrer">Google Play Services</a>
                </li>
                <li>
                    <a href='https://firebase.google.com/policies/analytics' target='_blank' rel="noopener noreferrer">Google Analytics for Firebase</a>
                </li>
            </ul>

            <h3>Afstand van aansprakelijkheid</h3>
            <p>Houd er rekening mee dat De Ontwikkelaar voor bepaalde dingen geen verantwoordelijkheid neemt. Voor een normale werking van de Apps is een actieve internetverbinding vereist. De verbinding kan Wi-Fi zijn, of geleverd door uw mobiele netwerkprovider, maar De Ontwikkelaar kan geen verantwoordelijkheid nemen als de app niet volledig functioneert als u geen toegang heeft tot Wi-Fi of het datanetwerk van uw provider.</p>

            <p>Als u de app buiten een gebied met wifi gebruikt, moet u er rekening mee houden dat uw voorwaarden van de overeenkomst met uw mobiele netwerkprovider nog steeds van toepassing zijn. Als gevolg hiervan kan uw mobiele provider u kosten in rekening brengen voor de datakosten voor de duur van de verbinding terwijl u de app opent, of andere kosten van derden. Door de app te gebruiken, aanvaardt u de verantwoordelijkheid voor dergelijke kosten, inclusief datakosten voor roaming als u de app buiten uw eigen territorium (d.w.z. regio of land) gebruikt zonder dataroaming uit te schakelen. Als u niet de rekeningbetaler bent voor het apparaat waarop u de app gebruikt, houd er dan rekening mee dat De Ontwikkelaar ervan uit gaat dat u toestemming heeft gekregen van de rekeningbetaler voor het gebruik van de app.</p>

            <p>Op dezelfde manier kan De Ontwikkelaar niet altijd de verantwoordelijkheid nemen voor de manier waarop u de app gebruikt, dwz u moet ervoor zorgen dat uw apparaat opgeladen blijft - als de batterij leeg is en u het niet kunt inschakelen om gebruik te maken van de service, kan De Ontwikkelaar daarvoor niet verantwoordelijk geacht worden.</p>

            <p>Met betrekking tot de verantwoordelijkheid van De Ontwikkelaar voor uw gebruik van de app, is het belangrijk om bij het gebruik van de app in gedachten te houden dat, hoewel De Ontwikkelar ernaar streeft dat de app ten allen tijde up-to-date en correct is, wordt er vertrouwd op derden voor het verstrekken van data zodat deze aan u ter beschikking gesteld kan worden. De Ontwikkelaar aanvaardt geen aansprakelijkheid voor enig verlies, direct of indirect, dat u ervaart als gevolg van het volledig vertrouwen op deze functionaliteit van de app.</p>

            <p>De mobiele app is momenteel beschikbaar op Android en iOS - de vereisten voor beide systemen (en voor eventuele aanvullende systemen waartoe besloten wordt de beschikbaarheid van de mobiele app uit te breiden) kunnen veranderen. U dient de toekomstige update te downloaden als u de mobiele app van <span className={utilStyles.princess}>Het Dautenmannetje</span> hierna wilt blijven gebruiken. De Ontwikkelaar belooft niet dat het de Apps altijd zal updaten zodat deze relevant voor u is en / of werkt met de Android & iOS-versie die u op uw apparaat heeft geïnstalleerd. U belooft echter altijd updates van de applicatie te accepteren wanneer deze aan u worden aangeboden.</p>

            <p>Het aanbieden van de Apps kan op elk moment beëindigd worden zonder u hiervan op de hoogte te stellen. Tenzij anders vermeld, zullen bij beëindiging de rechten en licenties die u in deze voorwaarden zijn verleend, eindigen en moet u stoppen met het gebruik van de app en deze (indien nodig) van uw apparaat verwijderen.</p>

            <h3>Wijzigingen aan deze Algemene Voorwaarden</h3>

            <p>Deze Algemene Voorwaarden kunnen ten allen tijde gewijzigd worden. Wijzigingen zullen via deze website aan gebruikers gemeld worden. Het is daarom aangeraden om deze Algemene Vooraarden geregeld te raadplegen, zodat u steeds op de hoogte bent van eventuele wijzigingen.</p>

            <p>Deze Algemene Voorwaarden zijn van kracht sinds <strong>2021-04-01</strong> en werden laatst gewijzigd op <strong>2021-04-16</strong>.</p>

            <h3>Contactinformatie</h3>
            <p>Indien u vragen of suggesties heeft betreffende deze Algemene Voorwaarden, dan kan u contact opnemen met De Bedenker en/of De Ontwikkelaar via <a href='mailto:feedback@hetdautenmannetje.be'>feedback@hetdautenmannetje.be</a>.</p>

            <p>Deze Algemene Voorwaarden werden gegenereerd door <a href='https://app-privacy-policy-generator.nisrulz.com/' target='_blank' rel="noopener noreferrer">App Privacy Policy Generator</a> en daarna vertaald en aangepast door De Ontwikkelaar.</p>
        </div>
    )
}

export default Terms
