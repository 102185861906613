import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import utilStyles from '../styles/utils.module.css'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { analytics, db } from '../firebase.config';

const Play = () => {
    const { id } = useParams();

    const [chapter, setChapter] = useState(null);

    useEffect(() => {
        const fetchChapter = async () => {
            const chapterRef = db.collection('chapters').doc(id);
            const doc = await chapterRef.get();
            if (!doc.exists) {
                throw Error('No such document!');
            } else {
                return doc.data();
            }
        }

        fetchChapter()
            .then((chapter) => setChapter(chapter))
            .catch(error => {
                    console.error(error);
                    window.location.href = "/";
                }
            );
    }, [id]);

    if (chapter) {
        return (
            <>
                <div className='playerWrapper'>
                    <div
                        className={[utilStyles.headingLg, utilStyles.princess, utilStyles.center].join(' ')}>{chapter.title}</div>
                    <AudioPlayer
                        src={chapter.url}
                        autoPlay
                        onPlay={
                            analytics
                                .logEvent(
                                    'user_played_chapter_web',
                                    { 'code': id }
                                )
                        }
                        customAdditionalControls={[]}
                    />

                    <p className='play-info'>
                        Je luistert naar "Het Dautenmannetje". Om dit verhaal vanaf het begin te horen, begeef je dan
                        naar&nbsp;
                        <a href="https://goo.gl/maps/nmb8CDPSHTaowPGi7" target="_blank" rel="noopener noreferrer">
                            Startpunt Dauteweyers
                        </a>
                    </p>
                </div>

                <img
                    src={process.env.PUBLIC_URL + '/images/dautenmannetje.png'}
                    style={{ alignSelf: 'center' }}
                    height="300" // Desired size with correct aspect ratio
                    width="153" // Desired size with correct aspect ratio
                    alt="Het Dautenmannetje"
                />
            </>
        )
    } else {
        return <></>
    }
}

export default Play
