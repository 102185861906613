import NpsRating from "./NpsRating";

const AppVisualiser = () => {
    return (
        <div className='app-visualiser'>
            <img src={'/images/marketing.png'}
                 alt="Screenshots van de app"/>
            <NpsRating/>
        </div>
    );
}

export default AppVisualiser;
