import {BrowserRouter as Router, Link, Redirect, Route, Switch} from 'react-router-dom';
import utilStyles from "./styles/utils.module.css";
import Play from "./components/Play";
import React from 'react';
import StoreLink from "./components/StoreLink";
import Info from "./components/Info";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import PageviewAnalytics from "./misc/PageviewAnalytics";
import AppVisualiser from "./components/AppVisualiser";

function App() {
    return (
        <Router>
            <PageviewAnalytics/>
            <div className="container">
                <header className='header'>
                    <h1 className={[utilStyles.princess, utilStyles.heading2Xl, utilStyles.center].join(' ')}>
                        <Link to='/'>Het Dautenmannetje</Link>
                    </h1>

                    <StoreLink/>
                </header>

                <main className='main'>
                    <Switch>
                        <Route path='/' exact render={() =>
                            (
                                <>
                                    <AppVisualiser />
                                    <Info/>
                                </>
                            )
                        }/>

                        <Route path='/afspelen/:id' component={Play}/>
                        <Route path='/algemene-voorwaarden' component={Terms}/>
                        <Route path='/privacy' component={Privacy}/>
                        <Redirect to="/"/>
                    </Switch>
                </main>

                <footer className='footer'>
                    <p>
                        Een project van &copy; <a href="https://www.willemclaesen.com" target="_blank" rel="noopener noreferrer">Willem
                        Claesen</a>
                    </p>
                    <p>
                        In samenwerking met <a href="https://www.diepenbeek.be" target="_blank" rel="noopener noreferrer">Gemeente Diepenbeek</a>
                    </p>
                    <hr/>
                    <p>
                        <Link to='algemene-voorwaarden'>Algemene voorwaarden</Link> - <Link
                        to='privacy'>Privacybeleid</Link>
                    </p>
                </footer>
            </div>
        </Router>
    );
}

export default App;
