import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyAV1BJoVizf_KknTSyPMsmBnMLzprwXDcg",
    authDomain: "het-dautenmannetje.firebaseapp.com",
    projectId: "het-dautenmannetje",
    storageBucket: "het-dautenmannetje.appspot.com",
    messagingSenderId: "600939479986",
    appId: "1:600939479986:web:af8bc4d5369b672ef11aab",
    measurementId: "G-FSSF83TBZS"
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const analytics = firebase.analytics();
