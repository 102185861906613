import React, { useEffect, useState } from "react";
import { db } from "../firebase.config";

const StoreLink = () => {
    const [iOSUrl, setIOSUrl] = useState('');
    const [playUrl, setPlayUrl] = useState('');

    useEffect(() => {
        fetchAppStoreLinks();
    });

    const fetchAppStoreLinks = async () => {
        const collection = db.collection('app-store-links');
        const iOSUrl = await collection.doc('apple-app-store').get();
        const playUrl = await collection.doc('google-play-store').get();

        setIOSUrl(() => iOSUrl.data().url);
        setPlayUrl(() => playUrl.data().url);
    }

    return (
        <div className='store-links'>
            <a href={playUrl} target="_blank" rel="noopener noreferrer"
            className='google-play'>
                <img alt='Ontdek het op Google Play'
                     src={'/images/badges/google-play-badge.svg'}/>
            </a>

            <a href={iOSUrl} target="_blank" rel="noopener noreferrer"
            className='app-store'>
                <img alt='Download in de App Store'
                     src={'/images/badges/app-store-badge.svg'}
                     style={{padding: '0 10px'}}
                />
            </a>
        </div>
    )
}

export default StoreLink;
