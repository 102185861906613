import React from "react";
import ImageZoom from "react-medium-image-zoom";
import YoutubeEmbed from "./YoutubeEmbed";

const Info = () => {
    return (
        <div className="info-container">
            <h2 className='info-header'>Ga op avontuur met Viktor en het Dautenmannetje</h2>
            <YoutubeEmbed embedId='LbDD3YpGRyE'/>
            <p>
                Maak kennis met Viktor: een doodgewone jongen die zich - op een niet zo doodgewone avond - plots
                in de Dauteweyers bevond.<br/><br/>
                Viktor neemt je mee in zijn verhaal op een wandeltocht van 5,6 km. Scan de QR codes en laat je meeslepen
                in het prachtige verhaal. <br/><br/>
                Onderweg maak je kennis met het Dautenmannetje en tal van andere toverachtige wezens, al
                stappend wegdromen en genieten van de mooie Diepenbeekse natuur.
            </p>
            <h3>Route info</h3>
            <ImageZoom
                image={{
                    src: 'images/route-small.jpg',
                    alt: 'Route info',
                    className: 'route',
                    style: {width: '508px'}
                }}
                zoomImage={{
                    src: 'images/route.png',
                    alt: 'Route info'
                }}
            />
            <h4>Vertrekpunt</h4>
            <p>
                <a href="https://goo.gl/maps/nmb8CDPSHTaowPGi7" target="_blank" rel="noopener noreferrer">
                    Wandelroutes Dauteweyers (Sint-Servatiusstraat, Diepenbeek)
                </a>
            </p>
            <p>
                De wandeling volgt de BLAUWE route. Deze route is 5,6 km lang en duurt ongeveer anderhalf uur.
            </p>
            <h3>Nuttige info</h3>
            <ul>
                <li>Vanaf <strong>24 april 2021</strong> is de wandeling voor onbepaalde duur doorlopend toegankelijk
                </li>
                <li>Zowel overdag als 's avonds aangeraden!</li>
                <li>Om de natuur niet te verstoren is het gebruik van een koptelefoon of oortjes sterk aangeraden</li>
                <li>Stevige wandelschoenen zijn aangeraden</li>
                <li>De wandeling is niet rolstoeltoegankelijk</li>
            </ul>
        </div>
    );
}

export default Info
