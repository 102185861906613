import { db } from "../firebase.config";
import React, { useEffect, useState } from "react";

const NpsRating = () => {
    const [npsRating, setNpsRating] = useState(0);
    const [numberOfRatings, setNumberOfRatings] = useState(0);
    const [items, setItems] = useState([]);

    const npsFull = '/images/nps.png';
    const npsHalf = '/images/nps-half.png';
    const npsGrey = '/images/nps-grey.png';

    useEffect(() => {
        fetchNpsRatings();
    });

    const fetchNpsRatings = async () => {
        const collection = await db.collection('nps').get();
        const average = collection.docs
            .map(rating => rating.data().score)
            .reduce((acc, value) => acc + value, 0) / collection.docs.length;

        setNpsRating(() => average);
        setNumberOfRatings(() => collection.docs.length);

        const imageItems = []
        for (let i = 0; i < 5; i++) {
            if ((average | 0) - i > 0) {
                imageItems.push(npsFull);
            } else if (average - i > 0) {
                imageItems.push(npsHalf);
            } else {
                imageItems.push(npsGrey);
            }
        }
        setItems(() => imageItems
            .map((item, index) =>
                <div key={index} className='img-wrapper'>
                    <img src={item} alt=''/>
                </div>
            )
        );
    }

    if (npsRating > 0) {
        return (
            <div className='nps-rating'>
                <div className='nps-ratings-wrapper'>
                    {items} <span className='rating'>{npsRating.toFixed(2)}/5</span><br/>
                </div>
                <p>
                    <small><i>(op basis van feedback van <strong>{numberOfRatings}</strong> gebruikers)</i></small>
                </p>
            </div>
        );
    } else {
        return (<></>);
    }
}

export default NpsRating;
