import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { analytics } from "../firebase.config";

const PageviewAnalytics = () => {
    let location = useLocation();
    useEffect(() => {
        const page_path = location.pathname + location.search;
        analytics.setCurrentScreen(page_path);
        analytics.logEvent("page_view", { page_path });
    }, [location]);
    return null;
}

export default PageviewAnalytics;
