import utilStyles from '../styles/utils.module.css';

const Privacy = () => {
    return (
        <div className='column-layout terms'>
            <h2>Privacyverklaring</h2>
            <p>De app, beschikbaar in de Google Play Store en Apple App Store als <span className={utilStyles.princess}>Het Dautenmannetje</span> en de web-app "www.hetdautenmannetje.be" (hierna: Apps) werden gebouwd als gratis diensten en worden aangeboden in de staat waarin ze zich bevinden.</p>

            <p>De Apps werden ontwikkeld door Steve Bottelbergs, eenmanszaak met maatschappelijke zetel te Elzenstraat 21 bus 3, 3500 Hasselt, ingeschreven in de kruispuntbank van ondernemingen onder het ondernemingsnummer BE0739.648.952 (hierna: De Ontwikkelaar).</p>

            <p>Deze Privacyverklaring beschrijft enerzijds de maatregelen die De Ontwikkelaar neemt om uw persoonsgegevens te beschermen en anderzijds welke rechten u heeft als betrokkene.</p>

            <p>Als u gebruik maakt van de hierboven beschreven Apps, gaat u akkoord dat De Ontwikkelaar informatie verzamelt en opslaat in functie van de doeleinden omschreven in deze verklaring. De verzamelde informatie wordt uitsluitend gebruikt met als doel de Apps te verbeteren. De Ontwikkelaar verbindt zich ertoe deze informatie niet te gebruiken noch te delen met derden tenzij zoals beschreven in deze verklaring.</p>

            <h3>Informatieverzameling en -gebruik</h3>
            <p>Tijdens het gebruik van de hierboven beschreven Apps worden bepaalde anonieme gebruiksgegevens verzameld en opgeslagen, b.v.: welk hoofdstuk u momenteel beluistert, welke functionaliteiten u gebruikt, of u bepaalde foutmeldingen te zien krijgt enzovoort. Deze gegevens worden verzameld om inzichten te verkrijgen over het gebruik van de Apps, fouten op te sporen en/of mogelijke verbeteringen te identificeren. Op geen enkel ogenblik worden gegevens zoals uw naam, telefoonnummer, contacten of andere persoonlijke gegevens verwerkt noch geraadpleegd.</p>

            <h3>Diensten van derden</h3>
            <p>
                De Apps gebruiken diensten van derden die mogelijk informatie kunnen verzamelen met als doel u te identificeren.
                <br/><br/>
                <strong>Links naar de privacyverklaringen van de gebruikte diensten van derden:</strong>
            </p>
            <ul>
                <li>
                    <a href='https://www.google.com/policies/privacy/' target='_blank' rel="noopener noreferrer">Google Play Services</a>
                </li>
                <li>
                    <a href='https://firebase.google.com/policies/analytics' target='_blank' rel="noopener noreferrer">Google Analytics for Firebase</a>
                </li>
            </ul>

            <h3>Loggegevens</h3>
            <p>De Ontwikkelaar wilt u informeren dat er, in het geval van een fout in een van de Apps, mogelijk gegevens en informatie verzameld kan worden (via third party diensten) op het door u gebruikte apparaat. Deze loggegevens kunnen informatie bevatten zoals het Internet Protocol ("IP") -adres van uw apparaat, de naam van het apparaat, de versie van het besturingssysteem, de configuratie van de App, de tijd en datum van het gebruik van de App en andere statistieken.</p>

            <h3>Cookies</h3>
            <p>Cookies zijn bestanden met een kleine hoeveelheid data die vaak worden gebruikt als anonieme, unieke identificatiegegevens. Deze worden vanaf de websites die u bezoekt naar uw browser gestuurd en worden opgeslagen in het interne geheugen van uw apparaat.</p>

            <p>De Apps gebruikt deze "cookies" niet expliciet, maar kan code en libraries van derden gebruiken die "cookies" gebruiken om informatie te verzamelen en hun diensten te verbeteren.</p>

            <h3>Links naar andere websites</h3>
            <p>De Apps bevatten mogelijk links naar andere websites. Indien u klikt op een van deze links, wordt u doorverwezen naar de desbetreffende site. Deze externe websites zijn niet in het beheer van De Ontwikkelaar, en dus ook niet onderhevig aan deze Privacyverklaring. U wordt dan ook sterk geadviseerd om de privacyverklaringen van deze externe websites door te nemen. De Ontwikkelaar heeft geen controle over en neemt geen verantwoordelijkheid voor de inhoud, privacyverklaringen of praktijken van een externe website of diensten van derden.</p>

            <h3>Wijzigingen aan deze privacyverklaring</h3>
            <p>Deze Privacyverklaring kan ten allen tijde gewijzigd worden. Wijzigingen zullen via deze website aan gebruikers gemeld worden. Het is daarom aangeraden om deze privacyverklaring geregeld te raadplegen, zodat u steeds op de hoogte bent van eventuele wijzigingen.</p>

            <p>Deze verklaring is van kracht sinds <strong>2021-04-01</strong> en werden laatst gewijzigd op <strong>2021-04-16</strong>.</p>

            <h3>Contactinformatie</h3>
            <p>Indien u vragen of suggesties heeft betreffende deze Privacyverklaring, dan kan u contact opnemen met De Ontwikkelaar via <a href='mailto:feedback@hetdautenmannetje.be'>feedback@hetdautenmannetje.be</a>.</p>

            <p>Deze Privacyverklaring werd gegenereerd door <a href='https://app-privacy-policy-generator.nisrulz.com/' target='_blank' rel="noopener noreferrer">App Privacy Policy Generator</a> en daarna vertaald en aangepast door De Ontwikkelaar.</p>
        </div>
    )
}

export default Privacy
